<template>
    <div class="content">
        <div class="slider">
            <v-carousel hide-delimiters :show-arrows="false" cycle height="12vw" id="web-carousel"> 
                <v-carousel-item src="../imgs/mockup2.jpg" cover style="width: 38vw;">
                </v-carousel-item>
                <v-carousel-item>
                    <div class="banner-item" style="background: rgb(233,52,33);
                                                    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);">
                        <div class="text-container">
                            <h2>Вступай в наши <br/> сообщества!</h2>
                            <p>Все новости и актуальная <br/> информация о нас</p>
                        </div>
                        <div class="socials">
                            <a href="https://t.me/j0bar"><img src="../imgs/icon-telegram.png" alt="tg"></a>
                            <a href="https://vk.com/j0bar"><img src="../imgs/icon-vk.png" alt="vk"></a>
                        </div>
                    </div>
                </v-carousel-item>
            </v-carousel>
            <v-carousel hide-delimiters :show-arrows="false" cycle height="40vw" id="mob-carousel">
                <v-carousel-item src="../imgs/mockup2mobile.jpg" cover style="width: auto;">
                </v-carousel-item>
                <v-carousel-item>
                    <div class="banner-item" style="background: rgb(233,52,33);
                                                    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);">
                        <div class="text-container">
                            <h2>Вступай в наши <br/> сообщества!</h2>
                            <p>Все новости и актуальная <br/> информация о нас</p>
                        </div>
                        <div class="socials">
                            <a href="https://t.me/j0bar"><img src="../imgs/icon-telegram.png" alt="tg"></a>
                            <a href="https://vk.com/j0bar"><img src="../imgs/icon-vk.png" alt="vk"></a>
                        </div>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </div>
        <div class="right-cards">
            <div v-if="bannerInfo" @click="Gotofirst" class="small-banner">
              <div class="text">
                <h3>{{ bannerInfo.first_banner.name }}</h3>
                <h2>
                  от
                  <span>{{ bannerInfo.first_banner.min_price }}</span>
                  ₽
                </h2>
              </div>
              <div class="image-back">
                <img :src="'https://j0bar.ru/media/' + bannerInfo.first_banner.image" alt="img">
              </div>
            </div>
      
            <div v-if="bannerInfo" @click="Gotosecond" class="small-banner">
              <div class="text">
                <h3>{{ bannerInfo.second_banner.name }}</h3>
                <h2>
                  от
                  <span>{{ bannerInfo.second_banner.min_price }}</span>
                  ₽
                </h2>
              </div>
              <div class="image-back">
                <img :src="'https://j0bar.ru/media/' + bannerInfo.second_banner.image" alt="img">
              </div>
            </div>
      
            <div v-if="bannerInfo" @click="Gotothird" class="small-banner">
              <div class="text">
                <h3>{{ bannerInfo.third_banner.name }}</h3>
                <h2>
                  от
                  <span>{{ bannerInfo.third_banner.min_price }}</span>
                  ₽
                </h2>
              </div>
              <div class="image-back">
                <img :src="'https://j0bar.ru/media/' + bannerInfo.third_banner.image" alt="img">
              </div>
            </div>
      
            <!-- Ваши текущие блоки для остальных маленьких баннеров -->
            <!-- ... -->
      
          </div>
        </div>
      </template>
      
      <script>
      import axios from 'axios';
      
      export default {
        data() {
          return {
            bannerInfo: null,
          };
        },
        mounted() {
          // Отправляем запрос к нашей Django view для получения информации о баннере
          axios.get('https://j0bar.ru/api/v1/banner_info/')
            .then(response => {
              this.bannerInfo = response.data;
            })
            .catch(error => {
              console.error('Error fetching banner info:', error);
            });
        },
        methods: {
            Gotothird(){
                this.$router.push('/vacancy/'+ this.bannerInfo.third_banner.id);
            },
            Gotosecond(){
                this.$router.push('/vacancy/'+ this.bannerInfo.second_banner.id);
            },
            Gotofirst(){
                this.$router.push('/vacancy/'+ this.bannerInfo.first_banner.id);
            },
        },
      };
      </script>

<style>
.content{
    display: flex;
    justify-content: space-between;
    margin: 2vw 25vw 0 25vw;
}

.small-banner{
    background-color: #F2F4F6;
    width: 10.5vw;
    border-radius: 1vw;
    height: 3.5vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: all .2s ease;
}

.small-banner:hover{
    background-color: #eaedf0;
    cursor: pointer;
}

.text{
    margin: .6vw;
    width: 60%;
}

.small-banner img{
    background-color: red;
    width: 200%;
    object-fit: cover;
}

.image-back{
    width: 40%;
}

.small-banner h3{
    font-size: .7vw;
    color: #706C61;
    font-weight: 600;
}

.small-banner h2{
    font-size: .9vw;
    color: #FF9F1C;
    font-weight: 600;
}

.right-cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#web-carousel{
    display: block;
}

#mob-carousel{
    display: none;
}

.socials{
    margin-top: 1vw;
}

.socials img{
    width: 4vw;
    margin-right: .5vw;
    transition: all .2s ease;
}

.socials img:hover{
    filter: brightness(80%);
}

.banner-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1vw 2vw;
    color: white;
    width: 38vw;
}

.banner-item h2{
    font-size: 1.8vw;
    font-weight: 700;
}

.banner-item p{
    margin-top: .5vw;
    font-size: 1vw;
    font-weight: 300;
}

.slider{
    border-radius: 1vw;
    overflow: hidden;
}

@media (max-width: 1000px) {
    .content{
        display: block;
        justify-content: space-between;
        margin: 2vw;
    }

    .small-banner{
        margin-top: 3vw;
        background-color: #F2F4F6;
        width: 40%;
        margin-right: 5px;
        border-radius: 3vw;
        height: 15vw;
        overflow: hidden;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        transition: all .2s ease;
    }

    .small-banner:hover{
        background-color: #eaedf0;
        cursor: pointer;
    }

    .text{
        text-align: center;
        margin: .6vw;
        width: 100%;
    }

    .small-banner img{
        visibility: hidden;
        background-color: red;
        width: 200%;
        object-fit: cover;
    }

    .image-back{
        width: 40%;
        display: none;
    }

    .small-banner h3{
        font-size: 4vw;
        color: #706C61;
        font-weight: 600;
    }

    .small-banner h2{
        font-size: 5vw;
        color: #FF9F1C;
        font-weight: 600;
    }

    &::-webkit-scrollbar:horizontal {
        height: 0;
        width: 0;
        display: none;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        display: none;
    }

    .right-cards{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-x: scroll;
        scrollbar-color: none;
    }

    #web-carousel{
        display: none;
    }

    #mob-carousel{
        display: block;
    }

    .socials{
        margin-top: 1vw;
        display: flex;
    }

    .socials img{
        width: 10vw;
        margin-right: .5vw;
        transition: all .2s ease;
    }

    .socials img:hover{
        filter: brightness(80%);
    }

    .banner-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 5vw 4vw;
        color: white;
        width: auto;
    }

    .banner-item h2{
        font-size: 7vw;
        font-weight: 700;
    }

    .banner-item p{
        margin-top: .5vw;
        font-size: 4vw;
        font-weight: 300;
    }

    .slider{
        border-radius: 3vw;
        overflow: hidden;
    }
}

</style>