<template>
  <div class="mainpage">
      <h1 id="h-head">Аккаунт</h1>
      <div class="content">
          <div class="user-information">
              <div class="left">
              <section>
                  <h2>Личные данные</h2>
                  <div class="data">
                      <h4>Имя</h4>
                      <div class="textarea">
                          <p>{{ userFirstName }}</p>
                          <button id="edit">
                              <img src="../imgs/icon-edit.png" alt="">
                          </button>
                      </div>
                  </div>
                  <div class="data">
                      <h4>Телефон</h4>
                      <div class="textarea">
                          <p>{{ userPhoneNumber }}</p>
                          <button id="edit">
                              <img src="../imgs/icon-edit.png" alt="">
                          </button>
                      </div>
                  </div>
                  <div class="data">
                      <h4>Почта</h4>
                      <div class="textarea">
                          <p>{{ userEmail }}</p>
                      </div>
                  </div>
                  <!-- <div class="data">
                      <h4>Пароль</h4>
                      <button @click="openDialog">
                          Изменить
                      </button>
                  </div> -->
              </section>
          </div>
          <div class="right">
              <img src="../imgs/mockup3.png" alt="">
          </div>
          </div>
          <section>
                  <h2>Избранные вакансии</h2>
                  <div class="cards">
                    <workcardComponent
                    v-for="(profession, index) in professions"
                    :key="index"
                    :Name="profession.name"
                    :StartPrice="profession.min_price"
                    :EndPrice="profession.max_price"
                    :City="profession.city_name"
                    :Description="profession.description"
                    :Id="profession.id"
                    @update-favorite="updateFavorite"
                  />
                  </div>
          </section>
      </div>
  </div>
</template>

  
  <script>
  import axios from 'axios';
  import workcardComponent from './work-card-component.vue';  
  export default {
    components: {
      workcardComponent,
    },
    data() {
      return {
        dialog: false,
        professions: [],
      };
    },
    methods: {
      openDialog() {
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false;
      },
      getFavorit() {
        axios
          .get('https://j0bar.ru/api/v1/favoriteproffesion', {
            params: {
              id: this.$store.state.user.id,
            },
          })
          .then((response) => {
            this.professions = response.data;
            
          })
          .catch((error) => {
            console.error('Error fetching proffesion data', error);
          });
      },
      updateFavorite() {
        this.getFavorit();
      },
    },
    computed: {
      loggedIn() {
        return Boolean(this.$store.state.user.loggedIn);
      },
      userFirstName() {
        return this.$store.state.user.first_name || 'ERROR';
      },
      userPhoneNumber() {
        return this.$store.state.user.phone_number || 'ERROR';
      },
      userEmail() {
        return this.$store.state.user.email || 'ERROR';
      },
    },
    mounted() {
      this.getFavorit();
    },
  };
  </script>
  

  <style scoped>

  .user-information{
      display: flex;
      justify-content: space-between;
  }
  .cards{
      margin-top: 1vw;
      display: grid;
      grid-template-columns: repeat(2, 49.5%);
      grid-column-gap: 1%;
      grid-row-gap: .5vw; 
      height: fit-content;
  }
  .textarea{
      display: flex;
      align-items: center;
  }
  
  button{
      background: rgb(233,52,33);
      background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
      color: white;
      padding: 5px 10px;
      border-radius: .5vw;
      font-size: 0.8vw;
  }
  
  #edit{
      display: none;
      width: 1.5vw;
      height: 1.5vw;
      border-radius: .5vw;
      transition: all .2s ease;
  }
  
  #edit:hover{
      filter: brightness(90%);
  }
  
  #edit img{
      width: .8vw;
      height: .8vw;
  }
  
  p{
        font-size: 0.8vw;
    }

    h2{
        font-size: 1.5vw;
    }
  
  h4{
      font-size: 0.8vw;
      font-weight: 400;
      color: #706C61;
  }
  
  #h-head{
      margin: 3vw 25vw 1vw 25vw;
      font-size: 2vw;
  }
  
  .content{
      background-color: #F2F4F6;
      border-radius: 2vw 2vw 0 0;
      padding: 0vw 25vw 3vw 25vw;
      min-height: 73vh;
      display: block;
      margin: 0;

  }
  
  .mainpage{
      height: 100%;
      margin-bottom: -1vw;
      display: flex;
      flex-direction: column;
  }
  
  .left{
      display: flex;
      flex-direction: column;
      justify-content: start;
  }
  
  .right{
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-top: 2vw;
  }
  
  .right img{
      width: 20vw;
      border-radius: 1vw;
  }
  
  .left section{
      margin: 2vw 0 2vw 0;
  }
  
  .data{
      margin-top: 1vw;
  }
  
  section p{
      color: #706C61;
      background-color: white;
      border: solid #706C61 1px;
      border-radius: .5vw;
      width: 15vw;
      padding: .2vw .5vw;
      margin-right: .5vw;
  }
  
  @media (max-width: 1000px){
    .user-information{
      display: block;
      justify-content: space-between;
    }
    .cards{
        margin-top: 1vw;
        display: block;
        grid-template-columns: repeat(2, 49.5%);
        grid-column-gap: 1%;
        grid-row-gap: .5vw; 
        height: fit-content;
    }
    .textarea{
        display: flex;
        align-items: center;
    }
    
    button{
        background: rgb(233,52,33);
        background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
        color: white;
        padding: 5px 10px;
        border-radius: .5vw;
        font-size: 0.8vw;
    }
    
    #edit{
        display: none;
        width: 1.5vw;
        height: 1.5vw;
        border-radius: .5vw;
        transition: all .2s ease;
    }
    
    #edit:hover{
        filter: brightness(90%);
    }
    
    #edit img{
        width: .8vw;
        height: .8vw;
    }
    
    p{
        font-size: 5vw;
    }

    h2{
        font-size: 6vw;
    }
    
    h4{
        font-size: 5vw;
        font-weight: 400;
        color: #706C61;
    }
    
    #h-head{
        font-size: 8vw;
        margin: 3vw 3vw 1vw 3vw;
    }
    
    .content{
        background-color: #F2F4F6;
        border-radius: 2vw 2vw 0 0;
        padding: 0vw 3vw 3vw 3vw;
        min-height: 90vh;
        display: block;
        margin: 0;

    }
    
    .mainpage{
        height: 100%;
        margin-bottom: -1vw;
        display: flex;
        flex-direction: column;
    }
    
    .left{
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    
    .right{
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 2vw;
    }
    
    .right img{
        width: auto;
        border-radius: 1vw;
    }
    
    .left section{
        margin: 2vw 0 2vw 0;
    }
    
    .data{
        margin-top: 1vw;
    }
    
    section{
        margin-top: 5vw;
    }

    section p{
        color: #706C61;
        background-color: white;
        border: solid #706C61 1px;
        border-radius: 3vw;
        width: 100%;
        padding: 1vw 3vw;
        margin-right: 3vw;
    }
  }
  </style>