<template>
  <div class="content">
    <div class="work">
      <!-- <a href="/#/" style="visibility: visible;">
        <button id="back-btn">Назад</button>
      </a> -->
      <div class="vacancy-content">
        <div class="left-part">
          <div class="header">
            <h2>{{ vacancyData.name }}</h2>
          </div>
          <div class="parameters">
            <div class="price">
              <p style="margin-right: 5px;">от</p>
              <p style="margin-right: 5px;">{{ vacancyData.min_price }}</p>
              <p style="margin-right: 5px;">до</p>
              <p>{{ vacancyData.max_price }}</p>
            </div>
            <p style="margin-right: 10px; margin-left: 10px;">•</p>
            <p>{{ vacancyData.city_name }}</p>
          </div>
          <p class="description">{{ vacancyData.description }}</p>
          <div class="buttons">
            <button class="access" @click="openDialog">Откликнуться</button>
            <button class="report">Пожаловаться</button>
          </div>
        </div>
        <div class="right-part">
          <img :src="getImageUrl(vacancyData.image)" alt="">
        </div>
      </div>
      <div v-if="dialog" :class="{'modal' : dialogClass, 'modal-hide' : !dialogClass}">
        <div class="modal-content">
          <div class="form">
            <h2>Отклик на вакансию</h2>
              <div>
                <p>Имя</p>
                <input @input="resetError" type="text" placeholder="Введите имя..." v-model="name" required>
              </div>
              <div>
                <p>Телефон</p>
                <input
                  type="tel"
                  placeholder="Введите телефон..."
                  v-model="phone"
                  maxlength="11"
                  minlength="11"
                  required
                  @input="resetError"
                />
              </div>
              <section>
                <p>Отправляя заявку, соглашаюсь с <a style="visibility: visible; color: #706C61;" href="/policy"> <br> политикой конфиденциальности</a></p>
              </section>
              <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                <button @click="submitForm">Подтвердить</button>
                <button @click="closeDialog" id="cancel">Отмена</button>
              </div>
              <section style="margin-top: 20px;">
                <p v-if="error">{{ error }}</p>
              </section>
            </div>
        </div>
      </div>
    </div>
    <div class="same">
        <h2>Похожие вакансии</h2>
        <div class="cards">
          <workcardComponent
            v-if="professions.length >= 1"
            :Name="professions[0].name"
            :StartPrice="professions[0].min_price"
            :EndPrice="professions[0].max_price"
            :City="professions[0].city_name"
            :Description="professions[0].description"
            :Id="professions[0].id"
            :Like="0"
          />

          <workcardComponent
            v-if="professions.length >= 2"
            :Name="professions[1].name"
            :StartPrice="professions[1].min_price"
            :EndPrice="professions[1].max_price"
            :City="professions[1].city_name"
            :Description="professions[1].description"
            :Id="professions[1].id"
            :Like="0"
          />

          <workcardComponent
            v-if="professions.length >= 3"
            :Name="professions[2].name"
            :StartPrice="professions[2].min_price"
            :EndPrice="professions[2].max_price"
            :City="professions[2].city_name"
            :Description="professions[2].description"
            :Id="professions[2].id"
            :Like="0"
          />
        </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios';
import workcardComponent from './work-card-component.vue';


export default {
  components: {
    workcardComponent,
  },
  computed: {
    vacancyData() {
      return this.localVacancyData;
    },
    loggedIn() {
      return Boolean(this.$store.state.user.loggedIn);
    },
    userFirstName() {
      return this.$store.state.user.first_name || ''; 
    },
    userPhone(){
      return this.$store.state.user.phone_number || '';
    }
  },
  data() {
    return {
      localVacancyData: {},
      dialog: false,
      dialogClass: false,
      name: '',
      phone: '',
      professions: [],
      error: '',
    };
  },
  methods: {

    updateProfessions() {
      console.log(this.selectedCities)
      axios
        .get('https://j0bar.ru/api/v1/simillarproffesion/', {
          params: { id: this.$route.params.vacancyid},
        })
        .then((response) => {
          this.professions = response.data;
        });
    },

    openDialog() {
      this.dialog = true;
      setTimeout(() => {
        this.dialogClass = true;
      }, 10);
    },
    closeDialog() {
      this.dialogClass = false;
      setTimeout(() => {
        this.dialog = false;
      }, 200);
    },
    submitForm() {
      if (this.phone.length<11 || this.name.length<3) {
        this.error = 'Пожалуйста, заполните все поля!';
        setTimeout(() => {
          this.error = '';
        }, 5000);
      }else{
        console.log(this.$route.params.vacancyid)
        const vacancyId = "https://j0bar.ru/vacancy/" + this.$route.params.vacancyid;
        
        axios.post(`https://j0bar.ru/send_message/`, {
          vacancy_id: vacancyId,
          name: this.name,
          phone: this.phone,  
        })
        .then((response) => {
          console.log(vacancyId)
          console.log('Response:', response.data);
          // Close the dialog
          this.closeDialog();
        })
        .catch((error) => {
          // Handle the error
          console.error('Error submitting form', error);
        });
      }
      
    },
    getImageUrl(image) {
      return image ? `https://j0bar.ru/${image}` : 'https://placehold.it/400x200';
    },
    resetError(){
      this.error = '';
    }
  },
  mounted() {
    
    const vacancyId = this.$route.params.vacancyid;
    this.name = this.loggedIn ? this.$store.state.user.first_name || '' : '';
    this.phone = this.loggedIn ? this.$store.state.user.phone_number || '' : '';
    axios
      .get(`https://j0bar.ru/api/v1/proffesionss/${vacancyId}/`)
      .then((response) => {
        this.localVacancyData = response.data;
      })
      .catch((error) => {
        console.error('Error fetching vacancy data', error);
      });

      this.updateProfessions()
      console.log(this.professions)
  },

};
</script>




<style scoped>
#cancel{
  background: transparent;
  color: #706C61;
  border: solid #706C61 1px;
}
.description{
  color: #706C61;
  width: 80%;
  margin-top: 2vw;
  white-space: unset;
  overflow: hidden;
  text-overflow: ellipsis;
}

.same{
  background-color: #F2F4F6;
  padding: 1vw 25vw;
}

.cards{
  margin-top: 1vw;
  display: grid;
  grid-template-columns: 33% 32% 33%;
  grid-template-rows: 1fr;
  grid-column-gap: 1%;

}
.form {
  display: flex;
  flex-direction: column;
}

.form div p{
  font-size: 1vw;
  color: #706C61;
  text-align: left;
  font-weight: 500;
  margin-bottom: .3vw;
}

.form div input{
  color: #323232;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: .2vw;
  font-size: 1vw;
  background-color: #ffffff;
  border: solid #706C61 1px;
  border-radius: .6vw;
  margin-bottom: 1vw;
}

.form button{
  font-size: 1vw;
  background: rgb(233,52,33);
  background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
  color: white;
  padding: 10px 0;
  border-radius: .5vw;
  transition: all .2s ease;
  margin-top: 1vw;
  width: 49%;
}

.form button:hover{
  filter: brightness(120%);
}

.modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transform: translateY(0);
  background-color: rgba(0, 0, 0, 0.4);
  transition: all .2s ease;
}

.modal-hide {
  transform: translateY(10px);
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all .2s ease;
  opacity: 0;
}

.modal-content {
  background-color: #FFFFFF;
  padding: 70px;
  border: none;
  border-radius: .2vw;
  width: auto;
}




.access{
  margin-top: 2vw;
  font-size: 1vw;
  background: rgb(233,52,33);
  background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
  color: white;
  padding: .5vw 0;
  width: 18vw;
  font-weight: 200;
  border-radius: .5vw;
  transition: all .2s ease;
}

.report{
  margin-top: .5vw;
  font-size: 1vw;
  border: #706C61 solid 1px;
  color: #706C61;
  padding: .5vw 0;
  width: 18vw;
  font-weight: 200;
  border-radius: .5vw;
  transition: all .2s ease;
}

.access:hover{
  filter: brightness(120%);
}

.report:hover{
  background-color: #e2e2e1;
}

.right-part img{
  width: 20vw;
  border-radius: 1vw;
}

#back-btn{
  color: #706C61;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 1vw;
}

.work{
  background-color: transparent;
  padding: 1vw 25vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;  
}

.vacancy-content{
    background-color: #F2F4F6;
    padding: 2vw;
    border-radius: 1vw;
    display: flex;
}

.parameters{
    display: flex;
    width: fit-content;
    justify-content: space-between;
    color: #706C61;
    font-weight: bold;
}

.price{
    display: flex;
}

.header{
    justify-content: space-between;
    display: flex;
    align-items: center;
}

#description{
    width: 80%;
    margin: 1vw 0 1vw 0;
    color: #706C61;
}

#goto{
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 5px 10px;
    border-radius: .5vw;
    transition: all .2s ease;
    font-size: 0.8vw;
}

#goto:hover{
  filter: brightness(120%);
}

p{
    font-size: 0.8vw;
}

h2{
    font-size: 1.5vw;
}

.content{
  display: block;
  margin: 0;
}

@media (max-width: 1000px){
  .modal-content {
    background-color: #FFFFFF;
    margin: 30px;
    border: none;
    padding: 25px;
    border-radius: 2vw;
    width: auto;
  }
  .description{
    color: #706C61;
    width: auto;
    margin-top: 2vw;
    white-space: unset;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .same{
    background-color: #F2F4F6;
    padding: 1vw 4vw;
  }

  .cards{
    margin-top: 3vw;
    display: block;
    justify-content: space-around;
  }
  .form {
    display: block;
    flex-direction: column;
  }

  .form div p{
    font-size: 4vw;
    color: #706C61;
    text-align: left;
    font-weight: 500;
    margin-bottom: 1vw;
  }


  
  .form div input{
    color: #323232;
    font-weight: 500;
    display: block;
    width: 100%;
    padding: 1vw;
    font-size: 5vw;
    background-color: #ffffff;
    border: solid #706C61 1px;
    border-radius: 3vw;
    margin-bottom: 5vw;
  }

  .form button{
    font-size: 4vw;
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 10px 0;
    border-radius: 3vw;
    transition: all .2s ease;
    width: 49%;
  }

  .form button:hover{
    filter: brightness(120%);
  }


  .work a{
    font-size: 4vw;
  }

  .right-part img{
    width: 100%;
    border-radius: 3vw;
  }

  #description{
      width: 80%;
      margin: 5vw 0 5vw 0;
      color: #706C61;
  }

  #goto{
      background: rgb(233,52,33);
      background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
      color: white;
      padding: 5px 10px;
      border-radius: 3vw;
      transition: all .2s ease;
      font-size: 4vw;
  }

  #goto:hover{
    filter: brightness(120%);
  }

  p{
      font-size: 4vw;
  }

  h2{
      font-size: 6vw;
  }

  .buttons{
    display: flex;
    flex-direction: column;
  }

  .access{
    margin-top: 2vw;
    font-size: 5vw;
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 10px 0;
    width: auto;
    font-weight: 200;
    border-radius: 3vw;
    transition: all .2s ease;
  }

  .report{
    margin-top: 2vw;
    font-size: 5vw;
    border: #706C61 solid 1px;
    color: #706C61;
    padding: 10px 0;
    width: auto;
    font-weight: 200;
    border-radius: 3vw;
    transition: all .2s ease;
  }

  .access:hover{
    filter: brightness(120%);
  }

  .report:hover{
    background-color: #e2e2e1;
  }

  #back-btn{
    color: #706C61;
    text-decoration: underline;
    font-weight: bold;
  }

  .work{
    background-color: transparent;
    padding: 1vw 2vw;
    min-height: 80vh;
  }

  .vacancy-content{
      margin: 2vw 0vw;
      background-color: white;
      border-radius: 1vw;
      min-height: 40vh;
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap-reverse;
  }

  .parameters{
      display: flex;
      width: fit-content;
      justify-content: space-between;
      color: #706C61;
      font-weight: bold;
  }

  .price{
      display: flex;
  }

  .header{
      justify-content: space-between;
      display: flex;
      align-items: center;
  }

  #description{
      width: 100%;
      margin: 1vw 0 1vw 0;
      color: #706C61;
  }
}


</style>