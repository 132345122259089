<template>
  <div id="mainpage">
    <div class="content">
      <v-tabs class="tabs-container" fixed-tabs>
        <v-tab>
          Вход
        </v-tab>
        <v-tab>
          Регистрация
        </v-tab>

        <v-tab-item>
          <div class="form">
            <div>
              <p>Номер телефона</p>
              <input @input="resetError" v-model="login" type="text" placeholder="Введите телефон...">
            </div>
            <div>
              <div style="display: flex; align-items: center;">
                <p>Пароль</p>
                <button @click="passreset" id="forgot-password">забыли пароль?</button>
              </div>
              <input @input="resetError" v-model="password" type="password" placeholder="Введите пароль...">
            </div>
            <button @click="loginUser">Войти</button>
            <p id="error-message" v-if="loginError">{{ loginError }}</p>
            
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="form">
            <div>
              <p>Имя</p>
              <input v-model="name" type="text" placeholder="Введите имя...">
            </div>
            <div>
              <p>Телефон</p>
              <div style="display: flex; margin-bottom: 1vw;">
                <input @input="resetError" maxlength="11" v-model="phoneinput" type="tel" id="phone" placeholder="Введите телефон..." style="margin: 0;" pattern="^\8\d{10}$" title="Введите корректный номер телефона (например, 81234567890)" required>
                <!-- <button id="sendCode" :disabled="isButtonDisabled" @click="sendCode">Отправить код</button> -->
              </div>
              <p v-if="error" id="error-message">{{ error }}</p>
              <!-- <div v-if="code_send" style="display: flex; align-items: center; margin-bottom: 1vw; justify-content: space-between;">
                <div>
                  <input @input="resetError" style="margin-bottom: 0;" v-model="code" type="text" placeholder="Последние 4 цифры звонка...">
                </div>
                <div v-if="timer > 0">
                  <p style="margin-bottom: 0;">{{ timer }} секунд</p>
                </div>
              </div> -->
            </div>
            
            <div>
              <p>Почта</p>
              <input @input="resetError" v-model="email" type="text" placeholder="Введите почту...">
            </div>
            <div>
              <p>Пароль</p>
              <input @input="resetError" v-model="password" type="password" placeholder="Введите пароль...">
            </div>
            <section>
                <p>Отправляя заявку, соглашаюсь с <a style="visibility: visible; color: #706C61;" href="/#/policy"> <br> политикой конфиденциальности</a></p>
            </section>
            <button @click="registerUser">Зарегистрироваться</button>
            <p id="error-message" v-if="loginError">{{ loginError }}</p>

          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      login: '',
      password: '',
      name: '',
      email: '',
      loginError: '',
      code_send: false,
      phonecode: '',
      code: '',
      ip: '',
      phoneinput: '8',
      error: '',
      CodeValid: false,
    };
  },
  computed: {
    timer() {
      return this.$store.state.timer;
    },
    isButtonDisabled() {
      return this.$store.state.isButtonDisabled;
    },
  },
  methods: {
    passreset(){
      this.$router.push('/resetpass');
    },
    resetError() {
      // Сброс ошибки при изменении значения в поле ввода номера телефона
      this.error = '';
    },
    handleInput() {
      if (this.phoneinput.length === 1) {
        this.phoneinput = '8';
      }
    },
    sendCode() {
      // Проверка валидности номера телефона
      const pattern = /^\8\d{10}$/;
      if (!pattern.test(this.phoneinput)) {
        this.error = 'Некорректный номер телефона';
        return; // Добавлено возвращение, если номер некорректный
      }

      const url = "https://j0bar.ru/api/v1/phone_code/";
      console.log(this.timer)
      const data = {
        phone: this.phoneinput.replace('+',''),
        ip: this.ip
      };

      this.$store.commit('setButtonDisabled', true);
      this.code_send = true;

      // Проверяем, был ли таймер активен до перезагрузки
      if (this.timer <= 0) {
        this.$store.commit('setTimer', 120);
        const countdown = setInterval(() => {
          this.$store.commit('setTimer', this.$store.state.timer - 1);

          if (this.$store.state.timer <= 0) {
            clearInterval(countdown);
            this.$store.commit('setButtonDisabled', false);
          }
        }, 1000);
      }

      axios.post(url, data)
        .then(response => {
          console.log("Запрос успешно выполнен:", response.data);
        })
        .catch(error => {
          console.error("Ошибка при выполнении запроса:", error);
        });
    },


    async loginUser() {
      try {
        const response = await axios.post('https://j0bar.ru/api/v1/login/', {
          email_or_phone: this.login,
          password: this.password,
        });

        this.$store.commit('setUser', response.data);
        console.log(response.data);
        this.$router.go(-1);
      } catch (error) {
        console.error('Ошибка входа', error);
        this.loginError = 'Неверный логин или пароль.';
      }
    },

    checkcode(){
    const apiUrl = 'https://j0bar.ru/api/v1/check_phone_code/';

    const data = {
        phone: this.phoneinput.replace('+',''),
        code: this.code,
    };

    axios.post(apiUrl, data)
    .then(response => {
        console.log(response);
        this.CodeValid = response.data.is_valid;
    })
    .catch(error => {
        // Handle errors
        console.error('An error occurred while sending the request:', error);
    });
},
    async registerUser() {
      // this.checkcode()
      axios.post('https://j0bar.ru/api/v1/register/', {
        first_name: this.name,
        last_name: 'Pass',
        phone_number: this.phoneinput.replace('+', ''),
        email: this.email,
        password: this.password,
        // valid: this.CodeValid
      })
      .then((response) => {
        console.log(this.password);
        console.log(response.data);
        this.login = this.email;
        this.loginUser();
        // Обработка успешного запроса (статус код 200)
      })
      .catch((error) => {
        console.error('Ошибка регистрации', error.response);

        if (error.response.status === 400) {
          // Обработка ошибок с статус-кодом 400
          if (error.response.data.email !== undefined) {
            this.loginError = "Почта уже используется";
          } else if (error.response.data.phone_number !== undefined) {
            this.loginError = "Номер телефона уже используется";
          }else{
            this.loginError = "Повторите попытку регистрации"
          }
        } else {
          // Другие ошибки (не 400)
          // Здесь вы можете обработать другие статусы коды по вашему усмотрению
        }
      });
    },
    
  },
  mounted() {
    axios.get('https://api64.ipify.org?format=json')
  .then(response => {
    const ipAddress = response.data.ip;
    this.ip = ipAddress
  })
    if (this.timer > 0) {
      this.code_send = true;
      const countdown = setInterval(() => {
        this.$store.commit('setTimer', this.$store.state.timer - 1);

        if (this.$store.state.timer <= 0) {
          clearInterval(countdown);
          this.$store.commit('setButtonDisabled', false);
        }

        // Вызываем $forceUpdate() после изменения timer
        this.$forceUpdate();
      }, 1000);
    }
  },
};
</script>




<style scoped>
section p{
    font-size: 0.8vw;
}

section{
    margin-bottom: 1vw;
}

#forgot-password{
  margin-bottom: .3vw;
  margin-left: .5vw;
  font-size: .7vw;
  font-weight: 900;
  color: #E93421;
  background: transparent;
  width: auto;
  padding: 0;
}

#forgot-password:hover{
  color: #e97b21;
}

#error-message {
  margin-top: 15px;
  color: red;
  text-align: center;
  font-size: .7vw;
}


#mainpage{
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content {
  margin: 0vw 37vw;
  border-radius: .5vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #F2F4F6;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F2F4F6;
}

.v-tabs-slider{
  height: 0;
}

.v-tab{
  letter-spacing: normal;
  text-transform: none;
  font-size: 1vw;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #F2F4F6;
}
.form {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  background-color: #F2F4F6;
}

.form div p{
  font-size: 1vw;
  color: #706C61;
  text-align: left;
  font-weight: 500;
  margin-bottom: .3vw;
}

.form div input{
  color: #323232;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: .2vw;
  font-size: 1vw;
  background-color: #ffffff;
  border: solid #706C61 1px;
  border-radius: .6vw;
  margin-bottom: 1vw;
}

button{
  font-size: 1vw;
  background: rgb(233,52,33);
  background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
  color: white;
  width: 100%;
  padding: 10px 0;
  border-radius: .5vw;
  transition: all .2s ease;
}

#sendCode{
  font-size: .6vw;
  background: white;
  color: #706C61;
  width: 40%;
  padding: 0;
  border: solid #706C61 1px;
  border-radius: .6vw;
  transition: all .2s ease;
  margin-left: .5vw;
}

button:hover{
  filter: brightness(120%);
}

@media (max-width: 1000px){
  section p{  
    font-size: 4vw;
  }

  section{
    margin-bottom: 5vw;
  }

  #forgot-password{
    margin-bottom: .3vw;
    margin-left: 3vw;
    font-size: 4vw;
    font-weight: 900;
    color: #E93421;
    background: transparent;
    width: auto;
    padding: 0;
  }

  #forgot-password:hover{
    color: #e97b21;
  }

  #error-message {
    margin-top: 15px;
    color: red;
    text-align: center;
    font-size: 4vw;
  }

  #sendCode{
    font-size: 3vw;
    background: white;
    color: #706C61;
    width: 40%;
    padding: 0;
    border: solid #706C61 1px;
    border-radius: 3vw;
    transition: all .2s ease;
    margin-left: .5vw;
  }

  #mainpage{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .content {
    margin: 0vw 3vw;
    border-radius: 2vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: #F2F4F6;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #F2F4F6;
  }

  .v-tabs-slider{
    height: 0;
  }

  .v-tab{
    letter-spacing: normal;
    text-transform: none;
    font-size: 5vw;
    font-family: 'Montserrat';
    font-weight: bold;
    background-color: #F2F4F6;
  }
  .form {
    padding: 2vw;
    display: flex;
    flex-direction: column;
    background-color: #F2F4F6;
  }

  .form div p{
    font-size: 5vw;
    color: #706C61;
    text-align: left;
    font-weight: 500;
    margin-bottom: 1vw;
  }

  .form div input{
    color: #323232;
    font-weight: 500;
    display: block;
    width: 100%;
    padding: 1vw;
    font-size: 5vw;
    background-color: #ffffff;
    border: solid #706C61 1px;
    border-radius: 3vw;
    margin-bottom: 5vw;
  }

  button{
    font-size: 5vw;
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 10px 0;
    border-radius: 3vw;
    transition: all .2s ease;
  }

  button:hover{
    filter: brightness(120%);
  }
}
</style>
