<template>
  <div id="app">
    <navbarComponent/>
    <div id="fixed-block">
      <router-view></router-view>
      <footerComponent/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import navbarComponent from './components/navbar-component.vue'
import footerComponent from './components/footer-component.vue'

Vue.config.productionTip = false;

export default {
  name: "app",
  components: {
    navbarComponent,
    footerComponent
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {

  },
};
</script>

<style>
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ff5744;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ff5744, 0 0 5px #ff5744;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #ff5744;
  border-left-color: #ff5744;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
#fixed-block{
  margin-top: 7vh;
}

#app{
  font-family: 'Montserrat';
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content{
  padding-top: 1vw;
  background-color: #F2F4F6;
  min-height: 70vh;
  height: auto;
}

@media (max-width: 1000px){

  #fixed-block{
    margin-top: 25vw;
  }

  .main-content{
    background-color: #F2F4F6;
    border-radius: 2vw 2vw 0 0;
    min-height: 50vh;
    height: auto;
  }

  .work-cards{
    margin: 0vw 3vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

</style>
