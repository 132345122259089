<template>
    <div class="not-existing-page">
        <h1>404</h1>
        <p>Такой страницы не существует :(</p>
        <a href="/mainpage">На главную</a>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1{
    font-size: 5vw;
    color: #E93421;
}

p{
    font-size: 1vw;
}

a{
    color: #706C61;
    text-decoration: none;
    margin-top: .5vw;
    transition: all .2s ease;
}

a:hover{
    color: #35332e;
}
.not-existing-page{
    height: 85vh;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1000px){
    h1{
        font-size: 15vw;
        color: #E93421;
    }

    p{
        font-size: 5vw;
    }

    a{
        color: #706C61;
        text-decoration: none;
        margin-top: .5vw;
        transition: all .2s ease;
    }

    a:hover{
        color: #35332e;
    }
    .not-existing-page{
        height: 85vh;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }
}
</style>