<template>
  <div class="filters">
    <h3>Категории</h3>
    <div class="categories">
      <label v-for="category in categories" :key="category">
        <div class="line"></div>
        <input type="checkbox" v-model="selectedCategories[category]" :label="category" @change="onCategoriesChange" aria-label="category">
        {{ category }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array,
  },
  
  data() {
    return {
      selectedCategories: {},
    };
  },
  
  methods: {
    onCategoriesChange() {
      const selectedCategories = Object.keys(this.selectedCategories).filter(category => this.selectedCategories[category]);
      
      try {
        this.$emit('update:selectedCategories', selectedCategories);
      } catch (error) {
        console.error('Error emitting update:selectedCategories', error);
      }
    },
  },
  computed: {
    likesAllFruit () {
      return Object.values(this.selectedCategories).every(value => value);
    },
    likesSomeFruit () {
      return Object.values(this.selectedCategories).some(value => value);
    },
  },
};
</script>


<style scoped>
.line{
  height: 1px;
  width: 100%;
  background-color: #d2d3d4;
  margin-bottom: .5vw;
}
.filters {
  background-color: white;
  border-radius: 1vw;
  padding: 1vw;
  height: fit-content;
}

h3{
  color: #706C61;
  font-size: .8vw;
  text-align: left;
}

label{
  display: block;
  color: #706C61;
  font-weight: 200;
  margin-top: .5vw;
  font-size: .8vw;
  width: auto;
}

label:hover{
  cursor: pointer;
}

.input{
  color: #706C61;
  border-radius: .2vw;
}

@media (max-width: 1000px){
  .line{
    display: none;
    height: 1px;
    width: 100%;
    background-color: #d2d3d4;
    margin-bottom: .5vw;
  }
  .categories{
    display: flex;
    overflow-y: scroll;
  }
  .filters {
    background-color: white;
    border-radius: 3vw;
    margin: 1vw 0 2vw 0;
    padding: 5vw;
    height: fit-content;
  }

  h3{
    color: #706C61;
    font-size: 5vw;
    text-align: left;
  }

  label{
    flex-shrink: 0;
    display: block;
    color: #706C61;
    background-color: #f7f7f7;
    padding: 2vw;
    font-weight: 200;
    border-radius: 3vw;
    margin-top: .5vw;
    width: auto;
    margin-right: 1vw;
    font-size: 4vw;
  }

  label:hover{
    cursor: pointer;
  }

  .input{
    color: #706C61;
    border-radius: .2vw;
  }
}

</style>
