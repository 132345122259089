<template>
    <div class="mainpage">
        <h1 id="h-head">Восстановление пароля</h1>
        <div class="policy-content">
            <div class="left">
                <section>
                    <h2>E-mail</h2>
                    <p>E-mail при регистрации</p>
                    <div class="content-email">
                        <input :disabled="isdisabled" v-model="email" type="email" placeholder="Введите e-mail...">
                        <button :disabled="isdisabled" @click="send_email">Отправить</button>
                    </div>
                    <p v-if="access">{{ access }}</p>
                    <p>Введите код</p>
                    <div class="content-email">
                        <input v-model="code" type="text" placeholder="Введите код...">
                        <button @click="checkcode">Подтвердить</button>
                    </div>
                    <!-- Если такой аккаунт существует, то на эту почту будет отправлено письмо с ссылкой на восстановление пароля -->
                </section>
                <section v-if="CodeValid">
                    <h2>Новый пароль</h2>
                    <p>Новый пароль</p>
                    <input @input="resetError" v-model="newpass" type="password" placeholder="Введите новый пароль...">
                    <p>Повторите пароль</p>
                    <div class="content-email">
                        <input @input="resetError" v-model="newpassrepeat" type="password" placeholder="Повторите новый пароль...">
                        <button @click="savePassword">Сохранить</button>
                    </div>
                    <p v-if="passwordsDoNotMatch" style="color: red;">Пароли не совпадают!</p>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            email: "",
            newpass: '',
            newpassrepeat: '',
            passwordsDoNotMatch: false,
            CodeValid: false,
            code: '',
            isdisabled: false,
            access: '',
        };
    },
    methods:{

        send_email(){
            const apiUrl = 'https://j0bar.ru/api/v1/pass_reset/';

            const data = {
            email: this.email,
            };

            axios.post(apiUrl, data)
            .then(response => {
                // Обработка успешного ответа от сервера
                console.log('Ответ от сервера:', response.data);
                this.access = 'Письмо успешно отправлено';
                this.isdisabled = true;
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Произошла ошибка при отправке запроса:', error);
            });
        },
        checkcode(){
    const apiUrl = 'https://j0bar.ru/api/v1/checkcode/';

    const data = {
        email: this.email,
        code: this.code,
    };

    axios.post(apiUrl, data)
    .then(response => {
        console.log(response);
        this.CodeValid = response.data.is_valid;
    })
    .catch(error => {
        // Handle errors
        console.error('An error occurred while sending the request:', error);
    });
},
    

change_pass(){
    const apiUrl = 'https://j0bar.ru/api/v1/change_password/';

    const data = {
        email: this.email,
        code: this.code,
        password: this.newpass,
    };

    axios.post(apiUrl, data)
    .then(response => {
        console.log(response);
        this.CodeValid = response.data.is_valid;
    })
    .catch(error => {
        // Handle errors
        console.error('An error occurred while sending the request:', error);
    });
    this.$router.go(-1);
},

savePassword() {
            if (this.newpass === this.newpassrepeat) {
                console.log('Пароли совпадают, можно сохранить!');
                this.passwordsDoNotMatch = false;
                this.change_pass()
            } else {
                console.log('Пароли не совпадают!');
                this.passwordsDoNotMatch = true;
            }
            },
            resetError() {
                this.passwordsDoNotMatch = false;
            },
    }
}
</script>

<style scoped>
.content-email{
    display: flex;
    align-items: center;
}

button{
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: .2vw;
    font-size: 1vw;
    margin-left: .5vw;
    border-radius: .5vw;
    transition: all .2s ease;
    width: 8vw;
}

button:hover{
    filter: brightness(120%);
}

input{
    color: #323232;
    font-weight: 500;
    display: block;
    width: 15vw;
    padding: .2vw;
    font-size: 1vw;
    background-color: #ffffff;
    border: solid #706C61 1px;
    border-radius: .6vw;
}

p{
    font-size: 0.8vw;
}

h2{
    font-size: 1.5vw;
}

#h-head{
    margin: 3vw 25vw 1vw 25vw;
}

.policy-content{
    background-color: #F2F4F6;
    border-radius: 2vw 2vw 0 0;
    min-height: 80vh;
    padding: 0vw 25vw;
    display: flex;
    justify-content: space-between;
}

.mainpage{
    margin-bottom: -1vw;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.mainpage h1{
    font-size: 2vw;
}

.left section{
    margin: 2vw 0 2vw 0;
}

section p{
    white-space: unset;
    margin-top: .5vw;
    margin-left: 0vw;
    color: #706C61;
}

@media (max-width: 1000px){
    .content-email{
        display: flex;
        align-items: center;
    }

    button{
        background: rgb(233,52,33);
        background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
        color: white;
        padding: 1vw;
        font-size: 5vw;
        margin-left: .5vw;
        border-radius: 3vw;
        transition: all .2s ease;
        width: 40%;
    }

    button:hover{
        filter: brightness(120%);
    }

    input{
        color: #323232;
        font-weight: 500;
        display: block;
        width: 60%;
        padding: 1vw;
        font-size: 5vw;
        background-color: #ffffff;
        border: solid #706C61 1px;
        border-radius: 3vw;
    }

    p{
        font-size: 5vw;
    }

    h2{
        font-size: 6vw;
    }

    #h-head{
        font-size: 8vw;
        margin: 3vw 3vw 1vw 3vw;
    }

    .policy-content{
        background-color: #F2F4F6;
        border-radius: 2vw 2vw 0 0;
        padding: 5vw 3vw;
        display: block;
        min-height: 80vh;
    }

    .mainpage{
        height: 100%;
        margin-bottom: -1vw;
        display: flex;
        flex-direction: column;
    }

    .left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 2vw;
    }

    .mainpage h1{
        font-size: 2vw;
    }

    .left section{
        margin: 2vw 0 2vw 0;
    }

    section p{
        margin-top: .5vw;
        margin-left: .5vw;
        color: #706C61;
    }
}
</style>