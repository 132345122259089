<template>
    <div class="mainpage">
        <h1 id="h-head">Политика конфиденциальности</h1>
        <div class="policy-content">
            <div class="left">
                <section>
                    <h2>1. Общие положения</h2>
                    <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между j0bar.ru (далее j0bar.ru или Администрация) с одной стороны и пользователем сайта с другой.
Сайт j0bar.ru не является средством массовой информации.
<br/><br/>
Используя сайт j0bar.ru, Вы соглашаетесь с условиями данного соглашения.<br/><br/>
Если Вы не согласны с условиями данного соглашения, не используйте сайт j0bar.ru!
</p>
                </section>
                <section>
                    <h2>2. Права и обязанности сторон</h2>
                    <p>Пользователь имеет право: <br/>
- осуществлять поиск информации на сайте<br/>
- получать информацию на сайте<br/>
- Копировать информацию на другие сайты запрещено.<br/>
- использовать информацию сайта для личных некоммерческих целей<br/>
<br/>
Администрация имеет право:<br/>
- по своему усмотрению и необходимости создавать, изменять, отменять правила<br/>
- ограничивать доступ к любой информации на сайте<br/>
<br/>
Пользователь обязуется:<br/>
- не нарушать работоспособность сайта<br/>
- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами<br/>
<br/>
Администрация обязуется:<br/>
- поддерживать работоспособность сайта<br/>
</p>
                </section>
                <section>
                    <h2>Ответственность сторон</h2>
                    <p>- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами<br/>
- в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.)<br/><br/> Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
</p>
                </section>
                <section>
                    <h2>Условия действия Соглашения</h2>
                    <p>Данное Соглашение вступает в силу при любом использовании данного сайта.<br/><br/>
Данное Соглашение перестает действовать при появлении его новой версии.<br/><br/>
Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.<br/><br/>
Администрация не оповещает пользователей об изменении в Соглашении.<br/><br/><br/><br/>
</p>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
p{
    font-size: 0.8vw;
}

h2{
    font-size: 1.5vw;
}

#h-head{
    margin: 3vw 25vw 1vw 25vw;
}

.policy-content{
    background-color: #F2F4F6;
    border-radius: 2vw 2vw 0 0;
    min-height: 70vh;
    padding: 0vw 25vw;
    display: flex;
    justify-content: space-between;
}

.mainpage{
    margin-bottom: -1vw;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.mainpage h1{
    font-size: 2vw;
}

.left section{
    margin: 2vw 0 2vw 0;
}

section p{
    white-space: unset;
    margin-top: .5vw;
    margin-left: 0vw;
    color: #706C61;
}

@media (max-width: 1000px){
    p{
        font-size: 5vw;
    }

    h2{
        font-size: 6vw;
    }
    
    #h-head{
        font-size: 8vw;
        margin: 3vw 3vw 1vw 3vw;
    }

    .policy-content{
        background-color: #F2F4F6;
        border-radius: 2vw 2vw 0 0;
        padding: 5vw 3vw;
        display: block;
    }

    .mainpage{
        height: 100%;
        margin-bottom: -1vw;
        display: flex;
        flex-direction: column;
    }

    .left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 2vw;
    }

    .mainpage h1{
        font-size: 2vw;
    }

    .left section{
        margin: 2vw 0 2vw 0;
    }

    section p{
        margin-top: .5vw;
        margin-left: .5vw;
        color: #706C61;
    }
}
</style>