<template>
    <div class="mainpage">
        <h1 id="h-head">Сотрудничество</h1>
        <div class="policy-content">
            <div class="left">
                <section>
                    <h2>О компании</h2>
                    <p>Добро пожаловать на J0bar - сервис по поиску вакансий! Мы являемся ведущей компанией в сфере поиска работы и подбора персонала. Наша миссия - помочь людям найти работу своей мечты, а работодателям - найти лучших специалистов.</p>
                    <p>Мы предлагаем широкий выбор вакансий в различных отраслях и регионах. Наш сервис постоянно обновляется, чтобы вы могли найти самые свежие и актуальные предложения. Мы также предоставляем полезные советы и рекомендации по составлению резюме, прохождению собеседований и развитию карьеры.</p>
                    <p>Мы гордимся своей командой профессионалов, которые всегда готовы помочь вам в поиске работы или подборе персонала. Мы ценим каждого нашего клиента и стремимся предоставить ему наилучший сервис.</p>
                    <p>Если вы заинтересованы в сотрудничестве с нами, пожалуйста, свяжитесь с нами. Мы всегда открыты для новых идей и предложений. Мы готовы работать вместе, чтобы помочь вам достичь успеха в вашей карьере.</p>
                    
                </section>
                <section>
                    <h2>Контакты</h2>
                    <div class="social-links">
                        <p>• Tg: </p>
                        <a href="https://t.me/j0bar">@j0bar</a>
                    </div>
                    <div class="social-links">
                        <p>• Почта: </p>
                        <a>advert@j0bar.ru</a>
                    </div>
                </section>

                <section>
                    <p>Спасибо за выбор нашего сайта! Мы надеемся, что вы найдете здесь работу своей мечты или лучших специалистов для своей компании.</p>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.social-links{
    display: flex;
    align-items: center;
    margin-top: .5vw;
}

.social-links p{
    margin: 0 .5vw 0 0;
}

.social-links a{
    font-size: .8vw;
    font-weight: bold;
    color: #706C61;
}

p{
    font-size: 0.8vw;
}

h2{
    font-size: 1.5vw;
}

#h-head{
    margin: 3vw 25vw 1vw 25vw;
}

.policy-content{
    background-color: #F2F4F6;
    border-radius: 2vw 2vw 0 0;
    min-height: 70vh;
    padding: 0vw 25vw;
    display: flex;
    justify-content: space-between;
}

.mainpage{
    margin-bottom: -1vw;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.mainpage h1{
    font-size: 2vw;
}

.left section{
    margin: 2vw 0 2vw 0;
}

section p{
    white-space: unset;
    margin-top: .5vw;
    margin-left: 0vw;
    color: #706C61;
}

@media (max-width: 1000px){
    p{
        font-size: 5vw;
    }

    h2{
        font-size: 6vw;
    }

    #h-head{
        font-size: 8vw;
        margin: 3vw 3vw 1vw 3vw;
    }

    .policy-content{
        background-color: #F2F4F6;
        border-radius: 2vw 2vw 0 0;
        padding: 5vw 3vw;
        display: block;
    }

    .mainpage{
        height: 100%;
        margin-bottom: -1vw;
        display: flex;
        flex-direction: column;
    }

    .left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 2vw;
    }

    .mainpage h1{
        font-size: 2vw;
    }

    .left section{
        margin: 2vw 0 2vw 0;
    }

    section p{
        margin-top: .5vw;
        margin-left: .5vw;
        color: #706C61;
    }
    .social-links{
        display: flex;
        align-items: center;
        margin-top: 2vw;
    }

    .social-links p{
        margin: 0 2vw 0 0;
    }

    .social-links a{
        visibility: visible;
        font-size: 4vw;
        font-weight: bold;
    }
}
</style>