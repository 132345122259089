<template>
  <div class="footer">
    <div class="head">
        <!-- <img id="logo" src="../imgs/logo.png" alt="logo">
        <a href="/">Все вакансии</a>
        <a href="/#/contacts">Контакты</a>
        <a href="/#/nopage">О компании</a>
        <a href="/#/cooperation">Сотрудничество</a>
        <a href="/#/policy">Пользовательское соглашение</a> -->
    </div>
    <div class="bottom">
      <img id="logo" src="../imgs/logo.png" alt="logo">
      <hr>
      <p>© 2024 all rights reserved <br/> ИП Ахматнабиев Р. В.</p>
      <hr>
      <div class="social">
          <a href="https://t.me/j0bar"><img src="../imgs/icon-telegram.png" alt="tg"></a>
          <a href="https://vk.com/j0bar"><img src="../imgs/icon-vk.png" alt="vk"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
hr{
  height: 2vw;
  margin: 0 1vw;
}
.bottom{
  text-align: center;
  display: flex;
  justify-content: center;
  color: #706C61;
  margin: 1vw 0 1vw 0;
  align-items: center;
}
.social img{
  filter: brightness(40%);
  width: 1vw;
  margin-left: .2vw;
  transition: all .2s ease;
}

.social img:hover{
  filter: brightness(50%);
}

.footer{
  text-align: calc();
  padding: 1vw 25vw;
  background-color: #2B2B2B;
  /* border-radius: 2vw 2vw 0 0; */
  flex-grow: 1;
}

#logo{
  width: 6vw;
}

.head a{
  font-size: .7vw;
  transition: all .2s ease;
  color: #706C61;
  text-decoration: none;
}

.head a:hover{
  color: #E93421;
}

.head{
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px){
  hr{
    height: 10vw;
    margin: 0 3vw;
    display: none;
  }
  .social img{
    display: none;
    width: 8vw;
    margin-left: .5vw;
  }

  .footer{
    text-align: center;
    padding: 1vw 3vw;
    background-color: #2B2B2B;
    border-radius: 2vw 2vw 0 0;
    margin-top: auto;
    flex-grow: 1;
  }

  #logo{
    width: 20vw;
    display: none;
  }

  .head a{
    visibility: visible;
    font-size: 3vw;
    margin: 2vw 0;
    transition: all .2s ease;
  }

  .head a:hover{
    color: #E93421;
  }

  .head{
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .bottom{
    font-size: 3vw;
  }
}
</style>