<template>
  <div class="content">
    <div class="searchbar">
      <input type="text" placeholder="Поиск по вакансиям..." v-model="searchQuery" @input="handleInput" />
      <button @click="handleSearch">Найти</button>
    </div>
    <div class="location" @click="dialog = true">
      <img style="" src="../imgs/icon-filter.png" alt="icon">
      <p>{{ currentLocation }}</p>
    </div>
    <v-row justify="center" style="display: none;">
      <v-dialog v-model="dialog" scrollable width="auto">
        <div class="dialog">
          <h2>Выбрать город</h2>
          <v-combobox
            v-model="selectedCity"
            clearable
            label=""
            :items="cities"
            variant="underlined"
          ></v-combobox>
          <button @click="closeDialog" id="show">
            Смотреть вакансии
          </button>
        </div>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    cities: Array,
  },
  data() {
    return {
      searchQuery: '',
      selectedCities: {},
      selectedCity: null,
      dialog: false,
      currentLocation: 'Город',
    };
  },
  methods: {
    async updateLocation() {
      try {
        const ipinfoApiKey = '5cb7155d131d34';
        const apiKey = 'cf3584f8156d441bb6ff0b667c87798d';

        const response = await axios.get(`https://ipinfo.io/json?token=${ipinfoApiKey}`);
        const { loc } = response.data;

        const geocodingResponse = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${loc}&key=${apiKey}&language=ru`);
        const cityInRussian = geocodingResponse.data.results[0].components.city;
        this.currentLocation = cityInRussian;

        // Помечаем выбранный город в selectedCities
        this.$set(this.selectedCities, cityInRussian, true);
        const selectedCities = Object.keys(this.selectedCities).filter(city => this.selectedCities[city]);
        this.$emit('update:selectedCities', selectedCities);
        
        // Устанавливаем начальное значение для v-combobox
        this.selectedCity = this.currentLocation;
      } catch (error) {
        console.error('Ошибка при определении местоположения:', error);
      }
    },
    handleInput() {
      this.$emit('update:searchQuery', this.searchQuery);
    },
    handleSearch() {
      this.$emit('search');
    },
    closeDialog() {
      if (this.selectedCity) {
        // Очищаем и устанавливаем выбранный город
        this.selectedCities = {};
        this.$set(this.selectedCities, this.selectedCity, true);
        this.currentLocation = this.selectedCity
        this.$emit('update:selectedCities', [this.selectedCity]);
      }
      this.$emit('closeDialog');
      this.dialog = false;
    },
  },
  mounted() {
    this.updateLocation();
  },
};
</script>


<style scoped>
#show{
  background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
  color: white;
  padding: 5px 10px;
  border-radius: .5vw;
  transition: all .2s ease;
  font-size: 0.8vw;
}

#show:hover{
  filter: brightness(120%);
}
.dialog{
  background-color: white;
  padding: 1vw;
}
.content{
    display: flex;
    margin: 1.5vw 25vw;
    justify-content: space-between;
}

.location{
    width: 10.5vw;
    background-color: #F2F4F6;
    border-radius: 1vw;
    border: 1px solid #706C61;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
}

.location p{
  font-weight: bold;
  color: #706C61;
  font-size: .8vw;
}

.location:hover{
    background-color: #eaedf0;
    cursor: pointer;
}

.location img{
    width: .8vw;
    height: .8vw;
    margin-right: .2vw;
}
.searchbar{
    width: 38vw;
    background-color: #706C61;
    border-radius: .8vw;
    display: flex;
    align-items: center;
}

.searchbar button{
    text-align: center;
    font-size: .9vw;
    color: white;
    padding: .6vw 2.5vw;
    border-radius: .8vw;
    transition: all .2s ease;
}

.searchbar button:hover{
    color: orange;
}

.searchbar input {
  font-size: 0.8vw;
  display: block;
  width: 100%;
  padding: .6vw 2.5vw;
  background: white url("../imgs/icon-search.png") no-repeat 1vw center;
  background-size: 1vw 1vw;
  background-color: #F2F4F6;
  border: solid #706C61 1px;
  border-radius: .8vw;
}

.searchbar input:focus{
    outline: none;
}

@media (max-width: 1000px){
  #show{
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 5px 10px;
    border-radius: 3vw;
    transition: all .2s ease;
    font-size: 5vw;
  }

  #show:hover{
    filter: brightness(120%);
  }
  .dialog{
    background-color: white;
    padding: 5vw;
    width: 100%;
  }
    .content{
        display: block;
        margin: 1.5vw 2vw;
        justify-content: space-between;
    }
    .location{
        width: auto;
        background-color: #F2F4F6;
        border-radius: 3vw;
        padding: 1vw;
        border: 1px solid #706C61;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease;
        margin-top: 1vw;
    }

    .location p{
      font-weight: bold;
      color: #706C61;
      font-size: 4vw;
    }

    .location:hover{
        background-color: #eaedf0;
        cursor: pointer;
    }

    .location img{
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }
    .searchbar{
        width: auto;
        background-color: #706C61;
        border-radius: 3vw;
        display: flex;
        align-items: center;
    }

    .searchbar button{
        width: auto;
        text-align: center;
        font-size: 5vw;
        color: white;
        padding: .6vw 2.5vw;
        border-radius: 3vw;
        transition: all .2s ease;
    }

    .searchbar button:focus{
        color: orange;
    }

    .searchbar input {
        font-size: 5vw;
        display: block;
        width: 100%;
        padding: 3.5vw 6vw;
        background: white url("../imgs/icon-search.png") no-repeat 1vw center;
        background-size: 5vw 5vw;
        background-color: #F2F4F6;
        border: solid #706C61 1px;
        border-radius: 3vw;
    }

    .searchbar input:focus{
        outline: none;
    }

}
</style>