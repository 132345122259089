<template>
  <div class="card">
    <div class="header">
        <h2>{{ Name }}</h2>
        
        <v-tooltip v-if="Liked===0 && this.$store.state.user.id" text="Добавить в избранное">
          <template v-slot:activator="{ props }"> 
            <img v-bind="props" @click="addFavorite" src="../imgs/icon-heart.png" alt="heart">
          </template>
        </v-tooltip>
        <v-tooltip v-else-if="this.$store.state.user.id" text="Удалить из избранного">
          <template v-slot:activator="{ props }">
            <img v-bind="props" @click="addFavorite" src="../imgs/icon-heartactive.png" alt="heart">
          </template>
        </v-tooltip>
        
    </div>
    <div class="parameters">
        <div class="price">
            <p style="margin-right: 5px;">от</p>
            <p style="margin-right: 5px;">{{ StartPrice }}₽</p>
            <p style="margin-right: 5px;">до</p>
            <p>{{ EndPrice }}₽</p>
        </div>
        <p style="margin-right: 10px; margin-left: 10px;">•</p>
        <p>{{ City }}</p>
    </div>
    <p id="description">{{ Description }}</p>
    <router-link :to="{ name: 'vacancy', params: { vacancyid: Id }}">
      <button @click="reloadPage" id="goto">Открыть</button>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    Name: String,
    StartPrice: Number,
    EndPrice: Number,
    City: String,
    Description: String,
    Id: Number,
    Like: Number,
  },
  data(){
    return {
      Liked: this.Like,
    };
  },
  mounted(){
    const url = 'https://j0bar.ru/api/v1/isliked/';
    axios.get(url, {
            params: {
              id: this.Id,
              userId: this.$store.state.user.id
            },
          })
          .then((response) => {
            this.Liked = response.data.Liked;        
          })
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        location.reload();
      }, 310);
    },

    addFavorite() {
      console.log(this.$store.state.user)
      const url = 'https://j0bar.ru/api/v1/toggle_favorite_profession/';
      const userId = this.$store.state.user.id;
      const professionId = this.Id;

      const data = {
        user_id: userId,
        profession_id: professionId,
      };

      axios.post(url, data)
        .then(response => {
          console.log(response)
          this.$emit('update-favorite');
          if (this.Liked === 1){
            this.Liked = 0;
          }
          else{
            this.Liked = 1;
          }
        })
        .catch(error => {
          console.error('Error adding favorite:', error);
        });
    },
  },
};
</script>

<style scoped>
.card{
    padding: 1vw;
    background-color: white;
    border-radius: 1vw;
    width: auto;
    height: fit-content;
}

.parameters{
    display: flex;
    width: fit-content;
    justify-content: start;
    color: #706C61;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price{
    display: flex;
}

.header{
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.header img{
    width: 1vw;
    height: 1vw;
    transition: all .2s ease;
}


.header img:hover{
    cursor: pointer;
    filter: brightness(80%);
}

#description{
    width: 80%;
    margin: .7vw 0 .7vw 0;
    color: #706C61;
}

#goto{
    background: rgb(233,52,33);
    background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
    color: white;
    padding: 5px 10px;
    border-radius: .5vw;
    transition: all .2s ease;
    font-size: 0.8vw;
}

#goto:hover{
  filter: brightness(120%);
}

p{
    font-size: 0.7vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

h2{
    font-size: 1vw;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1000px){
  .card a{
    visibility: visible;
  }

  .card{
      padding: 4vw;
      background-color: white;
      border-radius: 3vw;
      width: 100%;
      margin-bottom: 2vw;
  }

  .parameters{
      display: flex;
      width: fit-content;
      justify-content: start;
      color: #706C61;
      font-weight: bold;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .price{
      display: flex;
  }

  .header{
      justify-content: space-between;
      display: flex;
      align-items: center;
  }

  .header img{
    width: 5vw;
    height: 5vw;
  }

  #description{
      width: 80%;
      margin: 5vw 0 5vw 0;
      color: #706C61;
  }

  #goto{
      background: rgb(233,52,33);
      background: linear-gradient(13deg, rgba(233,52,33,1) 0%, rgba(255,159,28,1) 100%);
      color: white;
      padding: 5px 10px;
      border-radius: 3vw;
      transition: all .2s ease;
      font-size: 4vw;
  }

  #goto:hover{
    filter: brightness(120%);
  }

  p{
      font-size: 3.5vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  h2{
      font-size: 6vw;
  }
}
</style>