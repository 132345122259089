<template>
  <div class="nav">
    <div style="display: flex; align-items: center;">
      <div class="logo">
        <a id="logo-href" href="/" style="margin-left: 0;">
          <img src="../imgs/logo.png" alt="logo">
        </a>
      </div>
      <router-link :to="'/'">Все вакансии</router-link>
      <router-link :to="'/contacts'">Контакты</router-link>
      <router-link :to="'/cooperation'">Сотрудничество</router-link>
      <router-link :to="'/policy'">Пользователям</router-link>
    </div>
    
    <div style="display: flex; align-items: center;">
      <template v-if="!loggedIn">
        <router-link :to="'/register'" id="register">Вход</router-link>
      </template>
      <template v-else>
        <div style="display: flex; align-items: center;">
          <router-link  id="register" :to="{ name: 'UserPage', params: { userid: userId }}">
            <div id="else" style="align-items: center;">
              <img src="../imgs/icon-user.png" style="width: .8vw; margin-right: 5px;" alt="">
              <p style="color: #706C61; font-weight: bold;">{{ userFirstName }}</p>
            </div>
          </router-link>
          <router-link :to="'/'"  style="margin: 0;">
            <button style="color: red;" @click="logout">
              <img src="../imgs/icon-door.png" style="width: 1vw; margin-left: .5vw;" alt="">
            </button>
          </router-link>
        </div>
      </template>
    </div>

    <!-- <div class="location">
      <img id="location" style="" src="../imgs/icon-location.png" alt="icon">
      <p style="font-weight: bold; text-decoration: underline; color: #706C61;">{{ Location }}</p>
    </div> -->
    <div class="menu-button">
      <img src="../imgs/icon-cross.png" alt="menu" @click="closeMenu" v-if="menuClass">
      <img src="../imgs/icon-menu.png" alt="menu" @click="openMenu" v-else>
    </div>

    <div v-if="menu" :class="{'menu-modal' : menuClass, 'menu-modal-hide' : !menuClass}" @click="closeMenu">
      <div class="menu-content">
        <router-link :to="'/'">Все вакансии</router-link>
        <router-link :to="'/contacts'">Контакты</router-link>
        <router-link :to="'/cooperation'">Сотрудничество</router-link>
        <router-link :to="'/policy'">Пользователям</router-link>

        <template v-if="!loggedIn">
          <router-link :to="'/register'" @click="closeMenu">Вход / Регистрация</router-link>
        </template>
        <template v-else>
          <div style="display: flex; align-items: center; margin-top: 2vw;" @click="closeMenu">
            <router-link id="register" :to="{ name: 'UserPage', params: { userid: userId }}">
              <img src="../imgs/icon-user.png" style="width: 5vw;" alt="">
              <p style="color: #706C61; font-weight: bold; margin-right: 1vw;">{{ userFirstName }}</p>
            </router-link>
            <router-link :to="'/'">
              <button style="color: red;" @click="logout">
                <img src="../imgs/icon-door.png" style="width: 7vw; height: 7vw; margin-left: 2vw;" alt="">
              </button>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      menuClass: false,
    };
  },
  computed: {
    loggedIn() {
      return Boolean(this.$store.state.user.loggedIn);
    },
    userFirstName() {
      return this.$store.state.user.first_name || '';
    },
    userId(){
      return this.$store.state.user.id || '';
    },
  },
  methods: {

    openMenu() {
      this.menu = true;
      setTimeout(() => {
        this.menuClass = true;
      }, 10);
    },
    closeMenu() {
      this.menuClass = false;
      setTimeout(() => {
        this.menu = false;
      }, 200);
    },

    logout() {
      this.$store.commit('logout');
      this.menu = false;
    },
  },
  created() {

  },
};
</script>


  

<style scoped>
#else{
    display: flex;
  }

.menu-button{
  display: none;
}

#location{
  width: .9vw;
  height: .9vw;
  margin-right: .2vw;
}

.nav{
    position: fixed;
    display: flex;
    padding: .2vw 25vw .2vw 25vw;
    background-color: white;
    filter: drop-shadow(0 -5px 10px rgb(229, 229, 229));
    z-index: 1;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.logo img{
    width: 6vw;
}

a{
    color: #706C61;
    font-size: .8vw;
    transition: all .2s ease;
    text-decoration: none;
    margin-left: 2vw;
}

a:hover{
    color: #3c3a34;
}

#register{
    background-color: #F2F4F6;
    border-radius: .5vw;
    padding: .5vw 1vw;
    color: #3c3a34;
    font-size: .6vw;
}

#register:hover{
    background-color: #e1e2e4;
}

.location{
    align-items: center;
    display: flex;
    margin-right: 0;
}

@media (max-width: 1000px){
  #register{
    background-color: #F2F4F6;
    border-radius: 3vw;
    padding: 3vw;
    color: #3c3a34;
    font-size: 4vw;
    display: flex;
    align-items: center;
  }

  #else{
    display: none;
  }

  .menu-modal {
    margin-top: 20vw;
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    align-items: start;
    justify-content: start;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease;
  }

  .menu-modal-hide{
    margin-top: 20vw;
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    align-items: start;
    justify-content: start;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transform: translateY(-20px);
    transition: all .2s ease;
  }

  .menu-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    border-radius: 0 0 3vw 3vw;
  }

  .menu-content a{
    visibility: visible;
    font-size: 5vw;
    margin: 2vw 0 2vw 0;
  }

  .menu-content a:hover{
    font-size: 5vw;
  }



  #logo-href{
    visibility: visible;
  }
  
  .menu-button{
    display: block;
  }

  .menu-button img{
    width: 10vw;
  }

  #location{
    width: 5vw;
    height: 5vw;
    margin-right: 1vw;
  }

  .nav{
    position: fixed;
    display: flex;
    padding: 3vw 3vw 0 3vw;
    background-color: white;
    z-index: 2;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
  }
  .logo img{
      width: 30vw;
  }

  a{
      visibility: hidden;
      color: #706C61;
      font-size: 1vw;
      transition: all .2s ease;
  }

  a:hover{
      color: #3c3a34;
      font-size: 3vw;
  }

  .location{
      align-items: center;
      display: flex;
      margin-right: 5vw;
  }
}
</style>