// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      id: null,
      email: null,
      phone_number: null,
      first_name: null,
      last_name: null,
      loggedIn: false,
    },
    timer: 0,  // Добавлено: таймер в секундах
    isButtonDisabled: false,  // Добавлено: флаг блокировки кнопки
  },
  mutations: {
    setUser(state, userData) {
      state.user.id = userData.id;
      state.user.email = userData.email;
      state.user.phone_number = userData.phone_number;
      state.user.first_name = userData.first_name;
      state.user.last_name = userData.last_name;
      state.user.loggedIn = true;
      // Сохраняем данные пользователя в куки
      VueCookies.set('user', JSON.stringify(state.user), '1h'); // Укажите нужное время истечения срока
    },
    logout(state) {
      state.user.id = null;
      state.user.email = null;
      state.user.phone_number = null;
      state.user.first_name = null;
      state.user.last_name = null;
      state.user.loggedIn = false;

      // Удаляем данные пользователя из куки при выходе
      VueCookies.remove('user');
    },
    setTimer(state, value) {
      state.timer = value;
    },
    setButtonDisabled(state, value) {
      state.isButtonDisabled = value;
    },
    // Другие мутации при необходимости
  },
  actions: {
    // Действия при необходимости
  },
  getters: {
    // Геттеры при необходимости
  },
});

// Восстанавливаем данные пользователя из куки при загрузке страницы
const userFromCookies = VueCookies.get('user');
if (userFromCookies) {
  store.commit('setUser', userFromCookies);
}

// Восстанавливаем данные таймера из куки при загрузке страницы
const timerFromCookies = VueCookies.get('timer');
if (timerFromCookies) {
  store.commit('setTimer', parseInt(timerFromCookies));
}

// Восстанавливаем флаг блокировки кнопки из куки при загрузке страницы
const isButtonDisabledFromCookies = VueCookies.get('isButtonDisabled');
if (isButtonDisabledFromCookies) {
  store.commit('setButtonDisabled', isButtonDisabledFromCookies === 'true');
}

// Слушаем изменения таймера и флага блокировки и сохраняем в куки
store.watch(
  (state) => ({ timer: state.timer, isButtonDisabled: state.isButtonDisabled }),
  ({ timer, isButtonDisabled }) => {
    VueCookies.set('timer', timer.toString(), '1h');
    VueCookies.set('isButtonDisabled', isButtonDisabled.toString(), '1h');
  }
);

export default store;  // Экспорт объекта store
