<template>
  <div id="mainpage">
    <bannerComponent />
    <searchbarComponent :cities="citiesArray" @update:searchQuery="updateSearchQuery" @update:selectedCities="updateSelectedCities" @closeDialog="closeDialog" @search="updateProfessions"/>
    <div data-app class="main-content">
      <filtersComponent :categories="categoriesArray"  @update:selectedCategories="updateselectedCategories"/>
      <div class="work-cards" v-if="nodata === 1">
        <workcardComponent
          v-for="(profession, index) in filteredProfessions"
          :key="index"
          :Name="profession.name"
          :StartPrice="profession.min_price"
          :EndPrice="profession.max_price"
          :City="profession.city_name"
          :Description="profession.description"
          :Id="profession.id"
          :Like="0"
        />
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="red"></v-progress-circular>
        Загрузка вакансий
      </div>
    </div>
  </div>
</template>

<script>
import bannerComponent from './banner-component.vue'
import searchbarComponent from './searchbar-component.vue'
import filtersComponent from './filters-component.vue'
import workcardComponent from './work-card-component.vue'
import axios from 'axios'

export default {
  components: {
    bannerComponent,
    searchbarComponent,
    filtersComponent,
    workcardComponent,
  },
  data() {
    return {
      professions: [],
      citiesArray: [],
      categoriesArray: [],
      selectedCities: [],
      searchQuery: "",
      nodata: 0,
      selectedCategories: [],
    };
  },
  mounted() {
    axios
      .get('http://127.0.0.1:8000/api/v1/proffesion')
      .then((response) => {
        console.log(response)
        this.nodata = 1;
      })
      .catch((error) => {
        console.error('Error fetching proffesion data', error);
        this.nodata = 0;
      });

    axios
      .get('http://127.0.0.1:8000/api/v1/city')
      .then((response) => {
        this.citiesArray = response.data.map((item) => item.name);
      })
      .catch((error) => {
        console.error('Error fetching city data', error);
      });

    axios
      .get('http://127.0.0.1:8000/api/v1/category')
      .then((response) => {
        this.categoriesArray = response.data.map((item) => item.name);
      })
      .catch((error) => {
        console.error('Error fetching city data', error);
      });
  },

  methods: {
    updateProfessions() {
      console.log(this.selectedCities)
      axios
        .get('http://127.0.0.1:8000/api/v1/proffesion/', {
          params: { search_query: this.searchQuery, selectedCities: this.selectedCities, selectedCategories:this.selectedCategories},
        })
        .then((response) => {
          this.professions = response.data;
        });
    },

    updateSelectedCities(selectedCities) {
      this.selectedCities = selectedCities;
      this.updateProfessions();
    },

    updateselectedCategories(selectedCategories) {
      console.log('Received update:selectedCategories', selectedCategories);
      this.selectedCategories = selectedCategories;
      this.updateProfessions();
    },

    updateSearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },

    closeDialog() {
      // Здесь можно выполнить нужные действия после закрытия диалога
      // Например, вызвать updateProfessions
      this.updateProfessions();
    },
  },

  computed: {
    filteredProfessions() {
      return this.professions.filter((profession) => {
        return this.selectedCities.length === 0 || this.selectedCities.includes(profession.city_name);
      });
    },
  },
};
</script>




<style scoped>
.main-content{
  padding: 0vw 25vw;
  display: grid;
  grid-template-columns: 15% 84%;
  grid-template-rows: 1fr;
  grid-column-gap: 1%;
  padding-top: 1vw;
}

.work-cards{
  margin-bottom: -5vw;
  padding-bottom: 6vw;
  display: grid;
  grid-template-columns: repeat(2, 49.5%);
  grid-column-gap: 1%;
  grid-row-gap: .5vw; 
  height: fit-content;
}

@media (max-width: 1000px){
  .main-content{
    padding: 0vw 2vw;
    display: block;
    padding-top: 1vw;
  }
  .work-cards{
    margin: 0;
    padding-bottom: 6vw;
    display: block;
    height: fit-content;
  }
}



</style>