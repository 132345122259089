import VueRouter from 'vue-router'
import Mainpage from './components/page-mainpage-component.vue';
import RegisterPage from './components/page-register-component.vue';
import ContactsPage from './components/page-contacts-component.vue';
import PolicyPage from './components/page-policy-component.vue';
import CooperationPage from './components/page-cooperation-component.vue';
import VacancyPage from './components/page-workpage-component.vue';
import UserPage from './components/page-user-component.vue';
import NotFound from './components/page-notfound-component.vue';
import VueCookies from 'vue-cookies';
import NProgress from 'nprogress'; // Импортируйте nprogress
import 'nprogress/nprogress.css'; // Импортируйте стили nprogress
import Passwordreset from './components/password-reset-component.vue'
const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', name: 'Mainpage', component: Mainpage, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' }},
    { path: '/register', name: 'RegisterPage', component: RegisterPage, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' } },
    { path: '/contacts', name: 'ContactsPage', component: ContactsPage, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' } },
    { path: '/policy', name: 'PolicyPage', component: PolicyPage, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' } },
    { path: '/vacancy/:vacancyid', name: 'vacancy', component: VacancyPage, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' } },
    { path: '/resetpass',name:'respass',component: Passwordreset, meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' }},
    { path: '/cooperation', name: 'CooperationPage', component: CooperationPage , meta: { requiresAuth: true, title: 'Sked.Online - Настройки профиля' }},
    { 
      path: '/user/:userid', 
      name: 'UserPage', 
      component: UserPage,
      beforeEnter: (to, from, next) => {
        // Восстанавливаем данные пользователя из куки
        const userFromCookies = VueCookies.get('user');
        console.log(userFromCookies);

        if (!userFromCookies) {
          // Если пользователь не авторизован, перенаправляем с полной перезагрузкой
          window.location.href = '/';
        } else {
          // Если пользователь авторизован, перенаправляем без полной перезагрузки
          next();
        }
      },
    },
    

    { path: '/404', component: NotFound },
    { path: '*', redirect: '/404' },
  ],
});

// Добавление middleware для управления состоянием индикатора прогресса
router.beforeEach((to, from, next) => {
  // Запуск индикатора прогресса
  NProgress.start();
  NProgress.configure({ easing: 'ease', speed: 400 });
  NProgress.configure({ showSpinner: false });
  setTimeout(() => {
    next(); // Переход к следующему роуту после задержки
  }, 300);
});

router.afterEach(() => {
  // Завершение индикатора прогресса
  NProgress.done();
});

export default router;