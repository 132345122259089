<template>
    <div class="mainpage">
        <h1 id="h-head">Контакты</h1>
        <div class="contacts-content">
            <div class="left">
                <section>
                    <h2>Связаться с нами</h2>
                    <p>• Почта: advert@j0bar.ru</p>
                </section>
                <section>
                    <h2>Мы в соц. сетях</h2>
                    <div class="social-links">
                        <p>• Tg: </p>
                        <a href="https://t.me/j0bar">@j0bar</a>
                    </div>
                    <div class="social-links">
                        <p>• Vk: </p>
                        <a href="https://vk.com/j0bar">Vk</a>
                    </div>
                </section>
            </div>
            <div class="right">
                <iframe id="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A4e165d2f283ee8a4f805ba6f70e9c2708977b08234957e0429583a1080d602ce&amp;source=constructor" width="1020" height="678" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

p{
    font-size: 0.8vw;
}

h2{
    font-size: 1.5vw;
}
.social-links{
    display: flex;
    align-items: center;
    margin-top: .5vw;
}

.social-links p{
    margin: 0 .5vw 0 0;
}

.social-links a{
    font-size: .8vw;
    font-weight: bold;
    color: #706C61;
}


#map{
    border-radius: 1vw;
    height: 20vw;
    width: 30vw;
}

#h-head{
    margin: 3vw 25vw 1vw 25vw;
}

.contacts-content{
    background-color: #F2F4F6;
    border-radius: 2vw 2vw 0 0;
    padding: 0vw 25vw 3vw 25vw;
    display: flex;
    justify-content: space-between;
    min-height: 73vh;
}

.mainpage{
    height: 100%;
    margin-bottom: -1vw;
    display: flex;
    flex-direction: column;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 2vw;
}

.mainpage h1{
    font-size: 2vw;
}

.left section{
    margin: 2vw 0 2vw 0;
}

section p{
    margin-top: .5vw;
    color: #706C61;
}

@media (max-width: 1000px){
    p{
        font-size: 5vw;
    }

    h2{
        font-size: 6vw;
    }
    #map{
        border-radius: 1vw;
        border: #b3b3b3 solid 1px;
        height: 100vw;
        width: 100%;
    }

    #h-head{
        font-size: 8vw;
        margin: 3vw 3vw 1vw 3vw;
    }

    .contacts-content{
        min-height: 85vh;
        background-color: #F2F4F6;
        border-radius: 2vw 2vw 0 0;
        padding: 5vw 3vw;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
    }

    .mainpage{
        height: 100%;
        margin-bottom: -1vw;
        display: flex;
        flex-direction: column;
    }

    .left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 2vw;
    }

    .mainpage h1{
        font-size: 2vw;
    }

    .left section{
        margin: 2vw 0 2vw 0;
    }

    section p{
        margin-top: .5vw;
        margin-left: .5vw;
        color: #706C61;
    }

    .social-links{
        display: flex;
        align-items: center;
        margin-top: 2vw;
    }

    .social-links p{
        margin: 0 2vw 0 0;
    }

    .social-links a{
        visibility: visible;
        font-size: 4vw;
        font-weight: bold;
    }
}
</style>